/* Profile.css */

/* Container for the entire profile component */
.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 600px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 14px 18px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* Styling the profile header */
.profile-header {
  text-align: center;
}

/* Styling the profile picture */
.profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #f7a306; /* Adding a border for emphasis */
  margin-bottom: 20px;
}

/* Styling the user name */
.user-name {
  font-size: 2em;
  margin: 10px 0;
  color: #333;
}

/* Styling the user email */
.user-email {
  font-size: 1.2em;
  color: #666;
}

/* Styling the edit button */
button {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #357ABD;
}
