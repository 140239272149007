.faq-container {
    background-color: #f8f9fa; /* Light background color */
    padding: 20px;
    border-radius: 12px; /* More rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Deeper shadow */
  }
  
  .custom-form {
    background-color: #ffffff; /* White background for form */
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-input,
  .form-textarea {
    border: 1px solid #ced4da; /* Border color */
    border-radius: 8px; /* Rounded corners */
    width: 100%; /* Full width */
    height: 50px; /* Increased height for input */
    padding: 10px; /* Inner padding */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  }
  
  .form-textarea {
    height: 150px; /* Increased height for textarea */
  }
  
  .form-input:focus,
  .form-textarea:focus {
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add focus shadow */
  }
  
  h2 {
    color: #343a40; /* Darker color for heading */
    margin-bottom: 20px;
    font-weight: bold; /* Make the heading bold */
  }
  
 
  
  button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  