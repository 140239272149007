 

.custom-form {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .custom-form .form-control {
    border-radius: 5px;
  }
  
  .custom-form .btn {
    border-radius: 5px;
  }
  .custom-select {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    font-size: 16px;
    color: #333;
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 5px;
    appearance: none; 
    background-image: url('../../assets/img/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px 16px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .custom-select:hover,
  .custom-select:focus {
    border-color: #80bdff;
    outline: none;
    background-color: #e9ecef;
  }
   
  .custom-select option:first-child {
    color: #5d4d05;
  }