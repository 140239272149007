/* ChangePassword.css */

.change-password-form {
  max-width: 500px;
  margin: 20px auto;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.change-password-form h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  font-weight: 600;
}

.change-password-form .form-group {
  margin-bottom: 20px;
}

.change-password-form .form-label {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
}

.change-password-form .form-control {
  border-radius: 5px;
  border: 1px solid #ced4da;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.change-password-form .form-control:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 0.2rem rgba(74, 144, 226, 0.25);
}

.change-password-form .form-control.is-invalid {
  border-color: #dc3545;
  background-color: #f8d7da;
}

.change-password-form .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.change-password-form .form-control-feedback {
  color: #dc3545;
  font-size: 0.875rem;
}

.change-password-form .btn {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: #4a90e2;
  transition: background-color 0.3s ease;
}

.change-password-form .btn:hover {
  background-color: #357abd;
}

.change-password-form .alert {
  margin-bottom: 20px;
}
