/* Container styling for the form */
.container {
    padding: 20px;
    margin-top: 50px;
  }
  
  .custom-form {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading styling */
  .custom-form h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Form control styling */
  .custom-form .form-group {
    margin-bottom: 15px;
  }
  
  .custom-form .form-control {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  .custom-form .form-control:focus {
    border-color: #007bff;
    box-shadow: none;
  }
  
  /* Label styling */
  .custom-form .form-label {
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  /* Checkbox styling */
  .custom-form .form-check {
    margin-bottom: 15px;
  }
  
  .custom-form .form-check-input {
    margin-right: 10px;
  }
  
  /* Button styling */
  .custom-form .btn {
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .custom-form .btn-primary {
    background-color: #007bff;
    border: none;
    color: white;
  }
  
  .custom-form .btn-primary:hover {
    background-color: #0056b3;
    cursor: pointer;
  }
  
  /* Error message styling */
  .custom-form .form-control-feedback {
    color: #dc3545;
    font-size: 0.875em;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .custom-form {
      padding: 15px;
    }
  }
  