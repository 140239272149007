 

  /* Dashboard container styling */
.dashboard-container {
  padding: 20px;
  /* background-color: #f8f9fe; */
  
  
}

/* Header styles */
h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  /* text-align: center; */
  margin-bottom: 50px;
}

/* Card container spacing */
.card-stats {
  border: none;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.card-stats:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

/* Card title styles */
.card-title {
  font-size: 1.2rem;
  color: #5e72e4;
  margin-bottom: 10px;
}

/* Card value styles */
.h2 {
  font-size: 2rem;
  color: #32325d;
  font-weight: 700;
}

/* Icon styling */
.icon-shape {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  font-size: 1.5rem;
  border-radius: 50%;
}

/* Icon colors */
.bg-danger {
  background-color: #f5365c !important;
}

.bg-warning {
  background-color: #fb6340 !important;
}

.bg-yellow {
  background-color: #ffd600 !important;
}

.bg-info {
  background-color: #11cdef !important;
}

/* Card content row */
.card-body .row {
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card-stats {
    margin-bottom: 20px;
  }

  .h2 {
    font-size: 1.75rem;
  }

  h1 {
    font-size: 2rem;
  }
}
