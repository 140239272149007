.countries-table {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    /* background-color: #f9f9f9; */
    
  }
  
  .countries-table h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
   

.custom-form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-form .form-control {
  border-radius: 5px;
}

.custom-form .btn {
  border-radius: 5px;
}
 
.button {
  display: inline-block;
  border-radius: 7px;
  border: none;
  background: #1875FF;
  color: white;
  font-family: inherit;
  text-align: center;
  font-size: 13px;
  box-shadow: 0px 14px 56px -11px #1875FF;
  width: 10em;
  padding: 1em;
  transition: all 0.4s;
  cursor: pointer;
 }
 
 .button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
 }
 
 
 .button:hover span:after {
  opacity: 4;
  right: 0;
 }
 