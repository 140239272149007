.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  text-align: center;
  border: 1px solid rgb(252, 252, 252);
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
  background-color: #f8f9fa;
  color: #212529;
  height: 450px;  
}

.form-signin img {
  margin-bottom: 20px;
}

.form-floating {
  margin-bottom: 15px;
}

.form-control {
  padding: 10px;
  font-size: 16px;
}

.button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}

.checkbox label {
  font-size: 14px;
}

.invalid-feedback {
  display: block;
  color: red;
  font-size: 12px;
}

.link-secondary {
  color: #6c757d;
  font-size: 14px;
}

.link-secondary:hover {
  text-decoration: underline;
}
 