 
/* .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;  
    width: 70%;  
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);  
    z-index: 1000; 
  } */
   
  .btn-warning {
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  