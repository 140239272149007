body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.button {
  display: inline-block;
  border-radius: 7px;
  border: none;
  background: #1875FF;
  color: white;
  font-family: inherit;
  text-align: center;
  font-size: 13px;
  box-shadow: 0px 14px 56px -11px #1875FF;
  width: 10em;
  padding: 1em;
  transition: all 0.4s;
  cursor: pointer;
 }
 
 .button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
 }
 
 
 .button:hover span:after {
  opacity: 4;
  right: 0;
 }