 

.sidebar-header {
  background-color: #212529;
  border-bottom: 1px solid #444;
  padding: 20px;
  text-align: center;
 
  
 
}

 

.nav-link {
  color: #ffffff !important;
  display: flex;
  align-items: center;
  padding: 15px 25px;
  font-size: 1.1rem; 
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, transform 0.2s;
}

.nav-link:hover,
.nav-link.active {
  background-color: #495057;
  color: #ffffff !important;
}

.nav-icon {
  margin-right: 15px;
  font-size: 1.5rem;
}

.user-section {
  background-color: #212529;
  padding: 20px;
  border-top: 5px solid #495057;
}

.user-profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-icon {
  font-size: 2.5rem;
  color: #f78989;
}

.user-info {
  color: #f0ac00;
}

.user-name {
  font-weight: bold;
  font-size: 1.5rem;  
}

.user-role {
  margin: 0;
  color: #f9e424;
}

/* Media queries for responsiveness */
@media (max-width: 992px) {
  .sidebar {
    width: 80px;
  }

  .sidebar-logo {
    width: 50px;
  }

  .nav-link {
    text-align: center;
    padding: 10px;
    font-size: 1rem;
  }

  .nav-link .nav-icon {
    margin-right: 0;
  }

  .user-section {
    padding: 10px;
  }

  .user-profile {
    justify-content: center;
  }

  .user-info {
    display: none;
  }

  .user-icon {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .sidebar {
    width: 60px;
  }

  .sidebar-logo {
    display: none;
  }

  .nav-link {
    padding: 8px;
    font-size: 0.85rem;
  }

  .user-icon {
    font-size: 1.25rem;
  }
}
/* Sidebar.css */
.sidebar {
  width: 250px; 
  height: 100vh;  
  background-color: #333;  
  color: rgb(19, 18, 18);  
  padding: 20px;  
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);  
  position: fixed;  
  /* background-image: url("https://scontent.fluh1-2.fna.fbcdn.net/v/t39.2365-6/334060798_1213593096210822_7458523586248063424_n.png?_nc_cat=106&ccb=1-7&_nc_sid=e280be&_nc_ohc=2qz_ozMPSccQ7kNvgHNIqFl&_nc_ht=scontent.fluh1-2.fna&_nc_gid=ATkWSsAH-xfHfa0ulvVYloU&oh=00_AYDdrGBr_CtUQ5e6AyDyMm9XPEVV2TmFYTMCjCqWxiVeIQ&oe=6710C35A"); */

}

.sidebar h2 {
  margin: 0; /* Remove default margin */
  padding-bottom: 20px; /* Space below the title */
}

.sidebar ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove padding */
}

.sidebar li {
  margin: 15px 0; /* Space between items */
}

.sidebar a {
  color: white; /* Link color */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s; /* Transition effect */
}

.sidebar a:hover {
  color: #1e90ff; /* Change color on hover */
}

/* Sidebar.css */
.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #333;
  color: white;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align content */
}

.sidebar-logo {
  width: 80%; /* Adjust logo width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space below logo */
}

.sidebar h2 {
  margin: 0;
  padding-bottom: 20px;
  text-align: center; /* Center the title */
}

.nav-link {
  color: white;
  display: flex;
  align-items: center;
  padding: 10px;
  transition: background 0.3s, color 0.3s;
}

.nav-link:hover {
  background-color: #f1a308;
}

.nav-icon {
  margin-right: 10px; /* Space between icon and text */
}
